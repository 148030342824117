import { eventDateSchema, eventSchema } from '@i2e/components';
import { z } from 'zod';

import { Sentry } from '@/lib/sentry';
import { ResponseProp } from '@/types/common';

import { Pagination } from './pagination';

const eventSegmentSchema = z.object({
    id: z.number(),
    name: z.string(),
});

const eventRoleSchema = z.object({
    id: z.number(),
    name: z.string(),
    dates: eventDateSchema.array(),
});

const eventItemTypeSchema = z.enum(['item', 'access_zone_role']);

const eventItemSchema = z.object({
    id: z.number(),
    type: eventItemTypeSchema,
    dates: eventDateSchema.array(),
    name: z.string(),
    price: z.nullable(z.number()),
    maxAmount: z.number(),
    category: z
        .object({
            id: z.string(),
            name: z.string(),
        })
        .nullable(),
});

export const runValidateEvents = (events: unknown, context?: string) => {
    const validateEvents = z.array(eventSchema).safeParse(events);

    if (!validateEvents.success) {
        Sentry.captureException(validateEvents.error, {
            extra: {
                data: events,
            },
            tags: {
                context: context ?? 'Event schema validation failed',
            },
        });
        return validateEvents.error;
    }

    return null;
};

export const runValidateEventSegments = (segments: unknown, context?: string) => {
    const validateSegments = z.array(eventSegmentSchema).safeParse(segments);

    if (!validateSegments.success) {
        Sentry.captureException(validateSegments.error, {
            extra: {
                data: segments,
            },
            tags: {
                context: context ?? 'Event segments schema validation failed',
            },
        });
        return validateSegments.error;
    }

    return null;
};

export const runValidateEventItems = (items: unknown, context?: string) => {
    const validateItems = z.array(eventItemSchema).safeParse(items);

    if (!validateItems.success) {
        Sentry.captureException(validateItems.error, {
            extra: {
                data: items,
            },
            tags: {
                context: context ?? 'Event items schema validation failed',
            },
        });
        return validateItems.error;
    }

    return null;
};

export const runValidateEventRoles = (roles: unknown, context?: string) => {
    const validateRoles = z.array(eventRoleSchema).safeParse(roles);

    if (!validateRoles.success) {
        Sentry.captureException(validateRoles.error, {
            extra: {
                data: roles,
            },
            tags: {
                context: context ?? 'Event roles schema validation failed',
            },
        });
        return validateRoles.error;
    }

    return null;
};

export type Event = z.infer<typeof eventSchema>;
export type EventsResponse = ResponseProp<Event[]> & Pagination;
export type EventSegment = z.infer<typeof eventSegmentSchema>;
export type EventSegmentsResponse = ResponseProp<EventSegment[]>;
export type EventItem = z.infer<typeof eventItemSchema>;
export type EventItemsResponse = ResponseProp<EventItem[]>;
export type EventRole = z.infer<typeof eventRoleSchema>;
export type EventRolesResponse = ResponseProp<EventRole[]>;
export type EventItemType = z.infer<typeof eventItemTypeSchema>;

export { eventSchema, eventSegmentSchema, eventRoleSchema, eventItemSchema, eventItemTypeSchema };
